import { ProposalEntity } from "../StandardProposal/ProposalEntity"
import { GeneralProposalIsEntity } from "./GeneralProposalIsEntity"
import { GenProposalTariffItemTierEntity } from "./GeneralProposalTariffItemTierEntity"

export interface GeneralProposalEntity extends ProposalEntity {

    key: string,
    proposalNo?: string | null,
    tariffType?: string | null,
    tariffCode?: string | null,
    tariffCodeDesc?: string | null,

    tarItemTierList: GenProposalTariffItemTierEntity[],
    tarItemIsList: GeneralProposalIsEntity[],
    [key: string]: string | boolean | number | Date | null | undefined | Object
}

export const EMPTY_GENERAL_PROPOSAL_ENTITY: GeneralProposalEntity = {
    key: "",
    proposalNo: null,
    tariffType: null,
    tariffCode: null,
    effectiveDate: null,
    expiryDate: null,
    currencyCode: "",
    confirmedDate: null,
    status: 'NEW',
    rejectReason: null,
    remarks: "",
    tarItemTierList: [],
    tarItemIsList: [],
}